<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('form.categories.label')"
                label-for="categories"
                :state="errors && errors.categories ? false : null"
              >
                <v-select
                  id="categories"
                  v-model="item.categories"
                  :multiple="true"
                  :options="categoryOptions"
                  :reduce="val => val.value"
                />
                <b-form-invalid-feedback v-if="errors && errors.categories">
                  {{ errors.categories[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-tabs>
            <b-tab
              v-for="language in languages"
              :key="language.code"
            >
              <template #title>
                <b-img
                  :src="require('@/assets/images/flags/' + language.code + '.svg')"
                  height="16"
                  width="16"
                  class="mr-1"
                />
                <span class="d-none d-sm-inline">{{ language.title }}</span>
              </template>
              <b-row>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.question.label')"
                    :label-for="'title.' + language.code"
                  >
                    <b-form-input
                      :id="'title.' + language.code"
                      v-model="item.title[language.code]"
                      :state="errors && errors['title.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                      {{ errors['title.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.answer.label')"
                    :label-for="'description.' + language.code"
                    :state="errors && errors['description.' + language.code] ? false : null"
                  >
                    <b-form-textarea
                      :id="'description.' + language.code"
                      v-model="item.description[language.code]"
                      :state="errors && errors['description.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                      {{ errors['description.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button

            type="button"
            variant="outline-secondary"
            :to="{ name: 'admin:faq-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'

import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      item: null,
      languages: [],
      categoryOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    if (!Object.keys(this.categoryOptions).length) {
      await this.$http.get('/api/admin/categories', {
        params: {
          type: 'faq',
          status: 'enabled',
        },
      })
        .then(response => {
          this.categoryOptions = this.$options.filters.transformForVSelect(response.data, 'id', 'title')
        })
    }

    this.item = await this.transformData()
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async onSubmit() {
      const data = {
        title: this.item.title,
        description: this.item.description,
        categories: this.item.categories,
      }

      this.$http.post('/api/admin/faq', data)
        .then(() => {
          router.replace({ name: 'admin:faq-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    transformData() {
      const data = {
        title: {},
        description: {},
        categories: null,
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.description[language.code] = null
      })

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
